import { Card } from '@veneer/core';
import tokens from '@veneer/tokens';
import { MainTheme } from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { SubscriptionsWarningState } from 'src/utils/enums';
import styled from 'styled-components';

const getColor = (state: SubscriptionsWarningState) => {
  switch (state) {
    case SubscriptionsWarningState.PENDING:
      return tokens.color.royalBlue7;
    case SubscriptionsWarningState.EXPIRING:
      return '#212121';
    case SubscriptionsWarningState.ERROR:
      return '#CC2C00';
    case SubscriptionsWarningState.INACTIVE:
      return '#666666';
    case SubscriptionsWarningState.ACTIVE:
    default:
      return '#212121';
  }
};

export const SubscriptionCardContainer = styled(Card)`
  display: flex;
  padding: ${tokens.layout.size4};
  align-items: flex-start;
  align-self: stretch;

  @media (${MainTheme.mediaQueries.mobile}) {
    gap: ${tokens.layout.size3};
  }
`;

export const SubscriptionCardContent = styled.div`
  display: flex;
  align-items: center;
  gap: ${tokens.layout.size2};
  width: 100%;
`;

export const SubscriptionLogo = styled.div<{ $mt?: number }>`
  display: flex;
  min-width: 65px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  > img {
    margin-top: ${(props) => props?.$mt || 4}px;
  }
`;

export const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.layout.size2};
  width: 100%;

  @media (${MainTheme.mediaQueries.large}) {
    gap: ${tokens.layout.size4};
    flex-direction: row;
    width: 100%;
  }
`;

export const PlanDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: ${tokens.layout.size2};
  flex: 1 0 0;
`;

export const Status = styled.div`
  display: flex;
  padding: 2px 0px;
  align-items: center;
  gap: ${tokens.layout.size2};
  flex: 1 0 0;
  align-self: stretch;

  @media (${MainTheme.mediaQueries.large}) {
    justify-content: center;
    align-items: center;
  }

  > div {
    display: flex;
    padding: ${tokens.layout.size1} ${tokens.layout.size0};
    justify-content: center;
    align-items: center;
    gap: ${tokens.layout.size1};

    > :first-child {
      color: ${tokens.color.gray12};
      font-size: ${tokens.typography.size1};
      font-family: ${tokens.typography.family0};
      font-weight: 600;
      line-height: ${tokens.typography.lineHeight2}; /* 142.857% */
    }
  }
`;

export const SubscriptionSummary = styled(PlanDetails)`
  gap: ${tokens.layout.size1};
`;

type ColoredStatusProps = {
  state: SubscriptionsWarningState;
};

export const ColoredStatus = styled.span<ColoredStatusProps>`
  color: ${(props) => getColor(props.state)} !important;
`;
