import {
  Button,
  IconChevronLeft,
  IconChevronRight,
  LogoHpInstantInk,
  useDirection,
  useMediaQuery
} from '@veneer/core';
import {
  MainTheme,
  SubscriptionType
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import React, { useCallback, useMemo } from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { Logo } from 'src/components/Logo';
import {
  generateAnalyticsOfSubscriptionCardClickedByType,
  publishEvent
} from 'src/utils/analytics';
import { SubscriptionsWarningState, TypeNameEntities } from 'src/utils/enums';
import { formatDate, formatExpirationDate } from 'src/utils/masks';
import {
  ColoredStatus,
  PlanContainer,
  PlanDetails,
  Status,
  SubscriptionCardContainer,
  SubscriptionCardContent,
  SubscriptionLogo,
  SubscriptionSummary
} from './styles';
import { SubscriptionsCardProps } from './types';
import { getResourceId } from 'src/utils/utils';
import getSubscriptionWarningState from 'src/helpers/getSubscriptionWarningState';

export const SubscriptionsCard = ({ subscription }: SubscriptionsCardProps) => {
  const { translate, northboundAPIs } = useDependencyManagerContext();

  const { isRTL } = useDirection();

  const ChevronIcon = isRTL ? IconChevronLeft : IconChevronRight;

  const isMobile = useMediaQuery(MainTheme.mediaQueries.mobile);
  const country = northboundAPIs?.v1?.localization?.country;
  const language = northboundAPIs?.v1?.localization?.language;
  const navigation = northboundAPIs?.v1?.navigation;

  const type = subscription.type as keyof typeof SubscriptionType;

  const resourceId = getResourceId(subscription);
  const subscriptionId = subscription?.resourceId;

  const handleRedirectAndAnalytics = useCallback(() => {
    const analytics = generateAnalyticsOfSubscriptionCardClickedByType(
      type,
      resourceId,
      subscription?.startDate
    );

    publishEvent(analytics);

    navigation?.push(
      `/${country}/${language}/subscriptions/${subscriptionId}?type=${type}`
    );
  }, [
    country,
    subscriptionId,
    language,
    navigation,
    resourceId,
    subscription?.startDate,
    type
  ]);

  const getState = useCallback(() => {
    const statusText = {
      [SubscriptionsWarningState.PENDING]: `${translate(
        'subscriptions.subscriptionPending',
        'Subscription pending'
      )}`,
      [SubscriptionsWarningState.ACTIVE]: `${translate(
        'subscriptions.nextPayment',
        'Next payment:'
      )}`,
      [SubscriptionsWarningState.EXPIRING]: `${translate(
        'payments.expires',
        'Expires:'
      )}`,
      [SubscriptionsWarningState.ERROR]: `${translate(
        'subscriptions.actionRequired',
        'Action required'
      )}`,
      [SubscriptionsWarningState.INACTIVE]: `${translate(
        'subscriptions.inactive',
        'Inactive'
      )}`
    };

    const warningState: SubscriptionsWarningState = getSubscriptionWarningState(
      subscription?.state,
      subscription?.type
    );

    if (!statusText[warningState]) {
      return;
    }

    const getChargeDateFormatted = (
      warningState: SubscriptionsWarningState
    ) => {
      const chargeDate = subscription?.billing?.nextBillingCycle?.chargeDate;
      return warningState === SubscriptionsWarningState.ACTIVE
        ? formatDate(chargeDate)
        : formatExpirationDate(chargeDate);
    };

    const showChargeDate =
      warningState === SubscriptionsWarningState.ACTIVE ||
      warningState === SubscriptionsWarningState.EXPIRING;

    const isChargeDateValid = !!getChargeDateFormatted(warningState);

    return (
      <>
        <ColoredStatus
          state={warningState}
          className="caption"
          data-testid="subscription-status"
        >
          {showChargeDate && isChargeDateValid && statusText[warningState]}
          {!showChargeDate && statusText[warningState]}
        </ColoredStatus>
        {showChargeDate && isChargeDateValid && (
          <span className="caption">
            {getChargeDateFormatted(warningState)}
          </span>
        )}
      </>
    );
  }, [
    subscription?.billing?.nextBillingCycle?.chargeDate,
    subscription?.state,
    subscription?.type,
    translate
  ]);

  const instantInkPlan = subscription?.entities?.find(
    (item) => item.__typename == TypeNameEntities.INSTANTINKENTITY
  );

  const inkPlan = instantInkPlan?.product?.description || null;

  const hasPaper = subscription?.entities?.some(
    (item) => item.__typename === TypeNameEntities.INSTANTPAPERENTITY
  );

  const getPagesMonth = useCallback(() => {
    const plusPaper = hasPaper
      ? ` ${translate('common.plusPaper', '+ Paper')}`
      : '';

    return `${inkPlan} ${translate('common.pages', 'Pages')}${plusPaper}`;
  }, [hasPaper, inkPlan, translate]);

  const getPlanContainer = useCallback(
    (subscriptionTypeName: string, deviceName: string) => {
      return (
        <>
          <PlanContainer>
            <PlanDetails>
              <p className="body">{subscriptionTypeName}</p>
              <SubscriptionSummary>
                {!!deviceName && (
                  <span className="caption-small">{deviceName}</span>
                )}
                {!!inkPlan && (
                  <span className="caption-small">{getPagesMonth()}</span>
                )}
              </SubscriptionSummary>
            </PlanDetails>
            {!isMobile && (
              <Status>
                <div>{getState()}</div>
              </Status>
            )}
          </PlanContainer>
          <Button
            data-testid="button"
            style={{ color: 'black' }}
            appearance="ghost"
            leadingIcon={<ChevronIcon />}
            onClick={handleRedirectAndAnalytics}
          />
        </>
      );
    },
    [
      ChevronIcon,
      getPagesMonth,
      getState,
      handleRedirectAndAnalytics,
      inkPlan,
      isMobile
    ]
  );

  const InstantInkSubscription = useMemo(() => {
    const deviceName =
      subscription?.devices?.items[0]?.identity?.friendlyName ||
      subscription?.devices?.items[0]?.identity?.makeAndModel?.name;

    return (
      <SubscriptionCardContent data-testid="subscription-card-content-instant-ink">
        <SubscriptionLogo>
          <LogoHpInstantInk
            appearance="multicolor"
            stacked
          />
        </SubscriptionLogo>
        {getPlanContainer(
          `${translate('common.instantInk', 'HP Instant Ink')}`,
          deviceName
        )}
      </SubscriptionCardContent>
    );
  }, [getPlanContainer, subscription?.devices?.items, translate]);

  const AllInSubscription = useMemo(() => {
    const hpAllInName =
      subscription?.entities?.find(
        (item) => item?.__typename == TypeNameEntities.DEVICEENTITY
      )?.product?.name || null;

    return (
      <SubscriptionCardContent data-testid="subscription-card-content-all-in">
        <SubscriptionLogo $mt={6}>
          <Logo
            type="HP_ALL_IN"
            width={52}
            height={27}
          />
        </SubscriptionLogo>
        {getPlanContainer(
          `${translate('common.allInPrintPlan', 'HP All-In Plan')}`,
          hpAllInName
        )}
      </SubscriptionCardContent>
    );
  }, [getPlanContainer, subscription?.entities, translate]);

  const getSubscriptionCard = () => {
    if (subscription?.type === SubscriptionType.INSTANT_INK) {
      return InstantInkSubscription;
    }

    if (subscription?.type === SubscriptionType.HP_ALL_IN) {
      return AllInSubscription;
    }

    return null;
  };

  return (
    <>
      <SubscriptionCardContainer
        appearance="outlined"
        data-testid="subscription-card-container"
        content={getSubscriptionCard()}
      />
    </>
  );
};
