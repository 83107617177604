import { tokens } from '@veneer/tokens';
import { MainTheme } from '@hpx-core-experiences/react-my-account-commons/dist/index';
import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${tokens.layout.size1};

  && {
    h4 {
      font-size: ${tokens.typography.size7};

      @media (${MainTheme.mediaQueries.maxWidthMobile}) {
        font-size: ${tokens.typography.size6};
      }
    }

    p.body {
      font-size: ${tokens.typography.size2};
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.layout.size6};

  @media (${MainTheme.mediaQueries.mobile}) {
    gap: ${tokens.layout.size6};
  }

  @media (${MainTheme.mediaQueries.maxWidthMobile}) {
    gap: ${tokens.layout.size5};
  }
`;

export const DrillDownHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  svg {
    cursor: pointer;
  }

  .subtitle-regular {
    font-size: ${tokens.typography.size4};
  }
`;

export const ErrorAndLoading = styled.div`
  display: flex;
  justify-content: center;
`;
