export const getCurrencySymbol = (
  locale: string | undefined = undefined,
  currencyCode: string
): string => {
  try {
    return (0)
      .toLocaleString(locale, {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
      .replace(/\d/g, '')
      .trim();
  } catch (e) {
    return '';
  }
};
