import { SubscriptionType } from '@hpx-core-experiences/react-my-account-commons/dist';
import React, { memo, useMemo, useEffect, useCallback } from 'react';
import { FeatureFlagProps } from 'src/App';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import SubscriptionDetails from 'src/pages/SubscriptionDetails';
import Subscriptions from 'src/pages/Subscriptions';

// This regex matches URLs that start with "subscriptions/" followed by either a numeric ID or a UUID.
// The UUID format is represented by a specific pattern of hexadecimal characters separated by hyphens.
const subscriptionDetailsPattern =
  /^subscriptions\/(\d+|[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})$/;

const Router = (featureFlagProps: FeatureFlagProps) => {
  const { northboundAPIs } = useDependencyManagerContext();
  const navigation = northboundAPIs?.v1?.navigation;
  const pathname =
    navigation?.location?.pathname?.replace(/^\//, '')?.toLowerCase() || '';

  const queryParams = useMemo(() => new URLSearchParams(location.search), []);
  const type = queryParams.get('type');

  const handleRedirection = useCallback(() => {
    if (!pathname.startsWith('subscriptions')) {
      return;
    }

    const isValidType =
      type === SubscriptionType.HP_ALL_IN ||
      type === SubscriptionType.INSTANT_INK;

    if (subscriptionDetailsPattern.test(pathname) && !isValidType) {
      navigation.replace({
        pathname: '/subscriptions',
        search: new URLSearchParams().toString()
      });
    }
    // Clear search URL and redirect to /subscriptions so left menu is displayed
    else if (!subscriptionDetailsPattern.test(pathname)) {
      navigation.replace({
        pathname: '/subscriptions',
        search: new URLSearchParams().toString()
      });
    }
  }, [navigation, pathname, type]);

  useEffect(() => {
    handleRedirection();
  }, [handleRedirection]);

  const PageComponent = useMemo(() => {
    if (subscriptionDetailsPattern.test(pathname) && type) {
      return <SubscriptionDetails {...featureFlagProps} />;
    }

    return <Subscriptions {...featureFlagProps} />;
  }, [featureFlagProps, pathname, type]);

  return PageComponent;
};

Router.displayName = 'Router';

export default memo(Router);
